import { useState } from "react";
import { MinusOutlined } from "@ant-design/icons";
import { Typography, Switch, Space, App, Input, Modal } from "antd";

import { Loader } from "components/reusable/Loader";
import { useCreateCallbackURL } from "mutations";
import { getAdminPartnerId } from "util/selectors";

function isValidURL(url: string) {
  try {
    const _ = new URL(url);
    return true;
  } catch {
    return false;
  }
}

const CallbackFormModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}) => {
  const [callbackURL, setCallbackURL] = useState<string>("");
  const [urlStatus, setUrlStatus] = useState<boolean>(false);
  const [formAllowListing, setFormAllowListing] = useState<boolean>(false);

  const partnerId = getAdminPartnerId();

  const createCallback = useCreateCallbackURL();
  const { notification } = App.useApp();

  const onOk = () => {
    if (!callbackURL || !isValidURL(callbackURL)) {
      notification.error({
        message: "Invalid URL",
        description: "Please enter a valid callback URL",
      });
      return;
    }
    createCallback.mutate(
      {
        callbackUrl: callbackURL,
        urlStatus: urlStatus ? "active" : "inactive",
        isDefault: true,
        partnerId,
      },
      {
        onSuccess: () => {
          notification.success({
            message: "",
            description: "Callback url added successfully",
          });
          setIsOpen(false);
          setCallbackURL("");
          setFormAllowListing(false);
          setUrlStatus(false);
        },
        onError: (error) => {
          notification.error({
            message: "",
            description: `An error occured while adding callback: ${error.response?.data.error}`,
          });
          setIsOpen(false);
        },
      },
    );
  };

  return (
    <Modal
      okButtonProps={{
        className:
          "bg-neutral-off-black text-white hover:bg-neutral-off-black border-none",
      }}
      centered
      closable
      title="Create callback"
      open={isOpen}
      onOk={onOk}
      okText="Save"
      onCancel={() => setIsOpen(false)}
    >
      <div className="border-none flex flex-col mt-5 gap-2">
        {createCallback.isPending && (
          <span className="flex items-center justify-center">
            <Loader size="xl" />
          </span>
        )}

        <Typography.Text className="">Callback URL</Typography.Text>
        <Input
          className="border border-neutral-off-black"
          value={callbackURL}
          onChange={(e) => setCallbackURL(e.target.value)}
        />
        <Space>
          <Switch
            value={formAllowListing}
            onChange={() => setFormAllowListing(!formAllowListing)}
            checkedChildren={<MinusOutlined />}
            unCheckedChildren={<MinusOutlined />}
          />
          Allow whitelisting
        </Space>
        <Typography.Paragraph className="text-sm text-gray-500">
          This means we will not send callback responses to any other URLs
          except whitelisted URLs.
        </Typography.Paragraph>
        <Space>
          <Switch
            value={urlStatus}
            onChange={() => setUrlStatus(!urlStatus)}
            checkedChildren={<MinusOutlined />}
            unCheckedChildren={<MinusOutlined />}
          />
          Mark as Default
        </Space>
        <Typography.Paragraph className="text-sm text-gray-500">
          If no callback URL is specified in your job request, we will use this
          URL as your default callback URL.
        </Typography.Paragraph>
      </div>
    </Modal>
  );
};

export default CallbackFormModal;
