import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { CALLBACKS_QUERY_KEY } from "queries";
import { camelize } from "util/transformers";

interface CreateCallbackURL {
  callbackUrl: string;
  urlStatus: string;
  isDefault: boolean;
  partnerId?: string;
}

interface Response {
  message: string;
}

const createCallback = async (input: CreateCallbackURL): Promise<Response> => {
  const { data } = await axios.post<Response>("/callback_url", input);
  return camelize<Response>(data);
};

export const useCreateCallbackURL = (
  options?: UseMutationOptions<
    Response,
    AxiosError<{ error: string }>,
    CreateCallbackURL
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createCallback,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [CALLBACKS_QUERY_KEY] });
      options?.onSuccess?.(...args);
    },
  });
};
