import { ReactNode, useEffect, useState } from "react";
import { StarFilled, EllipsisOutlined, DeleteFilled } from "@ant-design/icons";
import { Card, Button, App, Popover } from "antd";

import { Loader } from "components/reusable/Loader";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import { useDeleteCallback, useUpdateCallbackURL } from "mutations";
import CopyIcon from "resources/img/icons/copy-icon.svg";

export const CopyButton = ({
  item,
  children,
}: {
  item: string;
  children?: ReactNode;
}) => {
  const { copyToClipboard, copySuccess } = useCopyToClipboard();
  const { notification } = App.useApp();
  useEffect(() => {
    if (copySuccess) {
      notification.success({
        message: "",
        description: "Callback url copied successfully",
      });
    }
  }, [copySuccess]);

  return (
    <Button
      icon={<img src={CopyIcon} alt="copy" className="w-4 h-4" />}
      type="link"
      className="text-black text-sm -ml-4"
      onClick={() => copyToClipboard(item)}
      disabled={copySuccess}
    >
      {children}
    </Button>
  );
};

const CallbackActionPopover = ({ id, link }: { id: string; link: string }) => {
  const [open, setOpen] = useState(false);
  const { modal, notification } = App.useApp();
  const deleteCallback = useDeleteCallback();
  const updateCallback = useUpdateCallbackURL();

  const onDeleteCallback = () => {
    modal.confirm({
      centered: true,
      closable: true,
      icon: null,
      okText: "Revoke",
      okType: "default",
      content: deleteCallback.isPending ? <Loader size="xl" /> : null,
      onOk: () => {
        deleteCallback.mutate(
          { id },
          {
            onError: (error) =>
              notification.error({
                message: "",
                description: `Failed to delete Callback URL: ${error.response?.data.error}`,
              }),
            onSuccess: () =>
              notification.success({
                message: "",
                description: "Callback URL deleted successfully",
              }),
          },
        );
      },
      title: "Are you sure you want to delete this callback?",
      type: "warning",
      okButtonProps: {
        className:
          "bg-secondary-red text-white hover:bg-secondary-red border-none",
      },
    });
  };
  const onMarkAsDefault = () => {
    modal.confirm({
      centered: true,
      closable: true,
      icon: null,
      okText: "Confirm",
      okType: "default",
      content: updateCallback.isPending ? <Loader size="xl" /> : null,
      onOk: () =>
        updateCallback.mutate(
          { id, isDefault: true },
          {
            onError: (error) =>
              notification.error({
                message: "",
                description: `Failed to mark Callback URL as default: ${error.response?.data.error}`,
              }),
            onSuccess: () =>
              notification.success({
                message: "",
                description: "Marked Callback URL as default successfully",
              }),
          },
        ),
      title:
        "This will be the main callback that will be used by your mobile SDK",
      type: "info",
      okButtonProps: {
        className:
          "bg-neutral-off-black text-white hover:bg-neutral-off-black border-none",
      },
    });
  };

  const Content = (
    <Card className="border-none flex flex-col">
      <CopyButton item={link}> Copy callback URL</CopyButton>
      <Button
        type="link"
        className="text-sm -ml-4 text-black flex items-center w-full"
        icon={<StarFilled className="text-primary-blue" />}
        onClick={onMarkAsDefault}
      >
        Mark as Default
      </Button>
      <Button
        type="link"
        className="text-sm -ml-4 text-black flex gap-3 flex-row"
        icon={<DeleteFilled className="text-secondary-red" />}
        onClick={onDeleteCallback}
      >
        Delete
      </Button>
    </Card>
  );

  return (
    <Popover
      trigger="click"
      open={open}
      placement="bottom"
      content={Content}
      onOpenChange={setOpen}
      className="border border-neutral-off-white"
    >
      <div className="flex items-center justify-center">
        <Button
          icon={<EllipsisOutlined className="text-xl" />}
          type="link"
          className="bg-neutral-off-white text-sm -ml-4 border rounded-lg"
          onClick={() => setOpen(true)}
        />
      </div>
    </Popover>
  );
};

export default CallbackActionPopover;
