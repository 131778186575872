import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { Callback } from "types/api/callback";
import { camelize } from "util/transformers";

interface GetCallbacksResponse {
  callbackUrls: Callback[];
}

interface CallbackInput {
  partnerId?: string;
}

const getCallbackUrls = async ({ partnerId }: CallbackInput) => {
  const url = partnerId
    ? `/callback_url?partnerId=${partnerId}`
    : "/callback_url";
  const { data } = await axios.get(url);
  return camelize<GetCallbacksResponse>({ callbackUrls: data.callbacks });
};

export const CALLBACKS_QUERY_KEY = "use_callback_urls";

export const useCallbackUrls = (
  input: CallbackInput,
  options?: Omit<
    UseQueryOptions<GetCallbacksResponse, AxiosError<{ errors: string[] }>>,
    "queryKey" | "queryFn"
  >,
) =>
  useQuery({
    queryKey: [CALLBACKS_QUERY_KEY, input],
    queryFn: () => getCallbackUrls(input),
    ...options,
  });
