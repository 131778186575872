import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import { CALLBACKS_QUERY_KEY } from "queries";

interface DeleteCallbackResponse {}

interface DeleteCallbackInput {
  id: string;
}

const deleteCallback = async ({ id }: DeleteCallbackInput) => {
  const { data } = await axios.delete<DeleteCallbackResponse>(
    `/callback_url/${id}`,
  );
  return data;
};

export const useDeleteCallback = (
  options?: UseMutationOptions<
    DeleteCallbackResponse,
    AxiosError<{ error: string }>,
    DeleteCallbackInput
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteCallback,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [CALLBACKS_QUERY_KEY] });
      options?.onSuccess?.(...args);
    },
  });
};
