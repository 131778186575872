import { ReactNode, FC, useState, useEffect } from "react";
import { StarFilled, MinusOutlined } from "@ant-design/icons";
import {
  Card,
  Typography,
  Switch,
  Button,
  Table,
  Space,
  App,
  Divider,
} from "antd";
import { format, parseISO } from "date-fns";

import CallbackActionPopover, {
  CopyButton,
} from "components/reusable/SecuritySettings/CallbackList/CallbackActionPopover";
import CallbackFormModal from "components/reusable/SecuritySettings/CallbackList/CallbackFormModal";
import { useCallbackAllowListing } from "mutations";
import { useCallbackUrls } from "queries";
import { getAdminPartnerId } from "util/selectors";

const columns = [
  {
    title: "Callback URL",
    dataIndex: "callbackUrl",
    key: "callbackUrl",
  },
  {
    title: "Author",
    dataIndex: "author",
    key: "author",
  },
  {
    title: "Created",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Last Used",
    dataIndex: "last_used",
    key: "last_used",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
  },
];

const DisplayTime = ({ timestamp }: { timestamp?: string }) => {
  if (!timestamp) return null;
  const date = parseISO(timestamp);
  return (
    <div>
      <Typography.Paragraph className="flex flex-col">
        {format(date, "d MMM, yyyy")}
        <span className="text-neutral-dark-grey text-sm">
          {format(date, "h:mm:ss a")}
        </span>
      </Typography.Paragraph>
    </div>
  );
};

const DisplayUrl = ({
  url,
  isDefault,
}: {
  url: string;
  isDefault: boolean;
}) => (
  <Space direction="vertical">
    <Space direction="horizontal">
      <Typography.Text>{url}</Typography.Text> <CopyButton item={url} />
    </Space>
    {isDefault && (
      <Space
        direction="horizontal"
        className="bg-neutral-off-white w-28 h-10 flex items-center justify-center rounded-lg"
      >
        <StarFilled className="text-primary-blue fill-primary-blue" />
        <Typography.Text className="text-secondary-dark-green font-bold">
          Default
        </Typography.Text>
      </Space>
    )}
  </Space>
);

const Tablecomponents = () => ({
  header: {
    cell: ({ children }: { children: ReactNode }) => (
      <th className="bg-neutral-white text-neutral-off-black font-bold">
        {children}
      </th>
    ),
  },
});

const CallbackList: FC = () => {
  const [allowListing, setAllowListing] = useState<boolean>();
  const partnerId: string | undefined = getAdminPartnerId();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data, isLoading } = useCallbackUrls({ partnerId });

  const dataSources = (data?.callbackUrls || []).map((url) => ({
    key: url.id,
    id: url.id,
    callbackUrl: <DisplayUrl url={url.callbackUrl} isDefault={url.isDefault} />,
    author: url.createdBy,
    created_at: <DisplayTime timestamp={url.createdAt} />,
    last_used: <DisplayTime timestamp={url.lastUsed} />,
    action: <CallbackActionPopover id={url.id} link={url.callbackUrl} />,
  }));

  useEffect(() => {
    setAllowListing(
      (data?.callbackUrls || []).some((url) => url.urlStatus === "active"),
    );
  }, [data?.callbackUrls]);

  const { modal, notification } = App.useApp();
  const callbackAllowListing = useCallbackAllowListing();

  const onAllowListingChange = () => {
    const newAllowListing = !allowListing;
    const title = newAllowListing
      ? "Enable Allow Listing"
      : "Disable Allow Listing";
    const content = allowListing
      ? "Once enabled, only jobs using callback URLs from this allowlist will be processed successfully. Any requests with unlisted URLs will be rejected."
      : "Disabling the allowlist will allow all jobs to be processed, regardless of their callback URLs. Use caution if security restrictions are needed.";
    modal.confirm({
      centered: true,
      closable: true,
      icon: null,
      okText: "Confirm",
      okType: "default",
      onOk: () => {
        const text = newAllowListing ? "Enable" : "Disable";
        callbackAllowListing.mutate(
          { allowListing: newAllowListing },
          {
            onError: (error) =>
              notification.error({
                message: "",
                description: `Failed to ${text} allow listing on callbacks: ${error.response?.data.error}`,
              }),
            onSuccess: () => {
              setAllowListing(newAllowListing);
              notification.success({
                message: "",
                description: `${text}d allow listing on callbacks successfully`,
              });
            },
          },
        );
      },
      title,
      content,
      type: "info",
      okButtonProps: {
        className:
          "bg-neutral-off-black text-white hover:bg-neutral-off-black border-none",
      },
    });
  };

  return (
    <Card className="mt-10">
      <Typography.Title className="text-secondary-dark-green">
        Callback URLs
      </Typography.Title>

      <Typography.Paragraph className="mt-0">
        Most of the requests you submit are processed asynchronously, which
        means the results are not immediately available. We require callbacks so
        we can post responses as we process the request.
      </Typography.Paragraph>
      <ul className="list-disc list-inside text-gray-700 space-y-2 ml-5">
        <li>
          The <span className="font-semibold">Default</span> callback URL will
          receive the responses unless you specify a callback URL while making a
          request.
        </li>
        <li>
          Responses sent as <span className="font-semibold">POST</span> request
          in <span className="font-semibold">JSON</span> format.
        </li>
        {/* TODO(solo: Uncomment this when we add the ability to ping the callback) <li>
          A <span className="font-semibold">Ping</span> will send a sample
          Payload to the URL. Adding/updating a new callback will send a ping.
        </li> */}
        <li>
          For security, consider{" "}
          <span className="font-semibold">whitelisting</span> callback URLs.
        </li>
      </ul>
      <div className="flex flex-row mt-5 mb-[-10px] pl-1">
        <span className="mt-4">
          <Switch
            checkedChildren={<MinusOutlined />}
            unCheckedChildren={<MinusOutlined />}
            value={allowListing}
            onChange={onAllowListingChange}
          />
          <Typography.Text className="ml-2">
            {allowListing ? "Disable" : "Enable"} Allow Listing
          </Typography.Text>
        </span>

        <Button
          size="large"
          type="default"
          onClick={() => setIsOpen(true)}
          className="bg-neutral-off-black text-white hover:bg-neutral-off-black border-none flex-end mb-1"
        >
          Add callback URL
        </Button>
        <CallbackFormModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>

      <Divider className="bg-neutral-off-white h-[2px]" />
      <Table
        className="mt-[-20px]"
        loading={isLoading}
        pagination={false}
        dataSource={dataSources}
        columns={columns}
        components={Tablecomponents()}
      />
    </Card>
  );
};

export default CallbackList;
