import React, { useState } from "react";
import useUrlState from "@ahooksjs/use-url-state";
import { useBoolean } from "ahooks";
import {
  App,
  Button,
  Card,
  Form,
  Modal,
  Result,
  Switch,
  Typography,
} from "antd";
import { Link, useHistory } from "react-router-dom";

import AutoPayModal from "components/auto_pay/AutoPayModal";
import { PaymentPlanModal, PLANS } from "components/payment-plans";
import PendingPayment from "components/payment-plans/PendingPayment";
import type {
  AutoPayConfig,
  PayCurrency,
} from "components/payment-plans/types";
import { CreateInvoiceResponse, useDeactivateAutoPay } from "mutations";
import { useWallet, usePricingPlan } from "queries";
import HistoryIcon from "resources/img/icons/bookmark.svg";
import TopUpIcon from "resources/img/icons/wallet-topup.svg";
import { formatMoney } from "util/i18n";
import { canUserView } from "util/route_util";
import { getAdminPartnerId, getUserType } from "util/selectors";
import DLocalPaymentForm from "./billing/DLocalPaymentForm";
import DlocalSaveCardForm from "./billing/DLocalSaveCardForm";

const BillingDashboard: React.FC = () => {
  const history = useHistory();
  const { modal, notification } = App.useApp();
  const [invoiceData, setInvoiceData] = useState<
    | (CreateInvoiceResponse & {
        amount: number;
        currency: string;
      })
    | undefined
  >();
  const [dlocalModalOpen, { setTrue: openDlocalModal }] = useBoolean();
  const [dlocalDetails, setDlocalDetails] = useState<
    | {
        dlocalKey: string;
        currency: string;
      }
    | undefined
  >();
  const [{ mode }] = useUrlState({ mode: "" });
  const [{ amount: amountPaid }] = useUrlState({ amount: "" });
  const [{ paymentRef }] = useUrlState({ paymentRef: "" });
  const [{ status: paymentStatus }] = useUrlState({
    status: "",
  });
  const userHasPaymentAccess = canUserView(["/partner/billing/payment"]);

  const getPartnerId = () =>
    localStorage.adminPartnerId ? getAdminPartnerId() : null;
  const deactivateAutoPayMutation = useDeactivateAutoPay();
  const partnerId = getPartnerId();
  const { data: wallet } = useWallet(partnerId, {
    placeholderData: (prev) => prev,
  });
  const { data: pricingPlanData } = usePricingPlan(partnerId, {
    placeholderData: (prev) => prev,
  });
  const paymentPlan = pricingPlanData?.pricingPlan.contractType;
  const designatedCurrency = pricingPlanData?.pricingPlan
    .designatedCurrency as PayCurrency;
  const exchangeRate = wallet?.exchangeRates[designatedCurrency];
  const isNotUSD = designatedCurrency && designatedCurrency !== "USD";
  const walletBalance =
    wallet && isNotUSD
      ? wallet.walletBalance * exchangeRate!
      : wallet?.walletBalance;

  const autoPay: AutoPayConfig | undefined =
    pricingPlanData && wallet
      ? {
          minBalance: pricingPlanData.pricingPlan.minimumWalletBalanceUSD,
          payAmount: pricingPlanData.pricingPlan.recurringTopUpAmountUSD,
          enabled: pricingPlanData.pricingPlan.autoPayEnabled,
          enabledCurrencies: pricingPlanData.pricingPlan.enabledCurrencies,
          designatedCurrency: pricingPlanData.pricingPlan.designatedCurrency,
          exchangeRates: wallet.exchangeRates,
        }
      : undefined;
  if (wallet && pricingPlanData && isNotUSD) {
    autoPay!.minBalance =
      pricingPlanData.pricingPlan.minimumWalletBalanceUSD * exchangeRate!;
    autoPay!.payAmount =
      pricingPlanData.pricingPlan.recurringTopUpAmountUSD * exchangeRate!;
  }

  const isPayAsYouGo = paymentPlan === "pay_as_you_go";
  const showAutoPayModal =
    mode === "auto-pay" && isPayAsYouGo && getUserType() !== "admin";

  const resetBillingContext = () => {
    setInvoiceData(undefined);
    history.replace("/partner/billing");
  };

  const onCreateInvoiceResponse = ({
    amount,
    currency,
    dlocalSfKey,
    invoice,
    paymentMethods,
  }: CreateInvoiceResponse & {
    amount: number;
    currency: string;
  }) => {
    setInvoiceData({
      amount,
      currency,
      dlocalSfKey,
      invoice,
      paymentMethods,
    });
  };

  const turnOffAutoPay = async () => {
    deactivateAutoPayMutation.mutate(
      {
        partner_id: getAdminPartnerId(),
        auto_pay_enabled: false,
      },
      {
        onSuccess: () => {
          history.push("/partner/billing");
          notification.success({
            message: "Auto-Pay disabled successfully",
          });
        },
        onError: (res) => {
          if (res.response?.data.error) {
            notification.error({
              message:
                res.response.data.error || "Auto-Pay could not be updated",
            });
          }
        },
      },
    );
  };

  const showDlocalFlow =
    invoiceData?.invoice && invoiceData.dlocalSfKey && userHasPaymentAccess;

  const onAutoPayPageChange = (result?: {
    dlocalKey: string;
    currency: string;
  }) => {
    if (result?.dlocalKey) {
      history.push("/partner/billing");
      setDlocalDetails(result);
      openDlocalModal();
    }
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-8">
        <Card classNames={{ body: "flex w-full h-full flex-col items-center" }}>
          <img className="w-12 h-12" src={TopUpIcon} />
          <Typography.Title className="h3 text-center" level={2}>
            Current Balance:{" "}
            {walletBalance && designatedCurrency
              ? formatMoney(walletBalance, designatedCurrency)
              : "--"}
          </Typography.Title>
          <Typography.Paragraph className="text-center">
            Plan: {paymentPlan ? PLANS[paymentPlan] : "--"}
          </Typography.Paragraph>
          {userHasPaymentAccess && (
            <Button
              block
              disabled={!paymentPlan}
              onClick={() =>
                history.push("?mode=change-payment-plan&step=planSelection")
              }
              type="link"
            >
              Change Plan
            </Button>
          )}
          <div className="grow" />
          <Button
            block
            disabled={!userHasPaymentAccess || !autoPay}
            onClick={() =>
              history.push("?mode=change-payment-plan&step=add-funds")
            }
            type="primary"
          >
            Add Funds
          </Button>
        </Card>
        {isPayAsYouGo && (
          <Card
            classNames={{ body: "flex w-full h-full flex-col items-center" }}
          >
            <img className="w-12 h-12" src={TopUpIcon} />
            <Typography.Title className="h3 text-center" level={2}>
              Auto-Pay
            </Typography.Title>
            <div className="w-56">
              <Form.Item
                className="mb-0"
                label="Auto-Pay"
                colon={false}
                labelCol={{ className: "grow text-left" }}
                wrapperCol={{ className: "grow-0" }}
                valuePropName="checked"
              >
                <Switch
                  checked={autoPay?.enabled}
                  disabled={getUserType() === "admin"}
                  onChange={(enabled) => {
                    if (enabled) {
                      history.push("/partner/billing?mode=auto-pay&step=setup");
                    } else {
                      modal.confirm({
                        title: "Are you certain you wish to disable Auto-pay?",
                        type: "warning",
                        okText: "Turn Off Auto-Pay",
                        onOk: turnOffAutoPay,
                        okButtonProps: { danger: true },
                      });
                    }
                  }}
                />
              </Form.Item>
              <div className="flex justify-between">
                <div>Minimum balance</div>
                <div>
                  {autoPay?.enabled && designatedCurrency
                    ? formatMoney(autoPay.minBalance, designatedCurrency)
                    : "--"}
                </div>
              </div>
              <div className="flex justify-between">
                <div>Auto-Pay amount</div>
                <div>
                  {autoPay?.enabled && designatedCurrency
                    ? formatMoney(autoPay.payAmount, designatedCurrency)
                    : "--"}
                </div>
              </div>
            </div>
            <div className="grow" />
            <Button
              block
              className="mt-4"
              disabled={getUserType() === "admin"}
              onClick={() => history.push("?mode=auto-pay")}
              type="primary"
            >
              Manage Auto-Pay
            </Button>
          </Card>
        )}
        <Card classNames={{ body: "flex w-full h-full flex-col items-center" }}>
          <img className="w-12 h-12" src={HistoryIcon} />
          <Typography.Title className="h3 text-center" level={2}>
            Wallet History
          </Typography.Title>
          <Typography.Paragraph className="text-center">
            View payment history and see statements for past usage
          </Typography.Paragraph>
          <div className="grow" />
          <Link
            to="/partner/billing/history"
            component={Button}
            className="w-full"
            type="primary"
          >
            View Wallet History
          </Link>
        </Card>
      </div>
      {autoPay && (
        <AutoPayModal
          isOpen={showAutoPayModal}
          autoPay={autoPay}
          onPageChange={onAutoPayPageChange}
        />
      )}
      {autoPay && (
        <PaymentPlanModal
          isOpen={
            mode === "change-payment-plan" &&
            !showDlocalFlow &&
            userHasPaymentAccess
          }
          paymentPlan={paymentPlan!}
          autoPay={autoPay}
          designatedCurrency={designatedCurrency}
          onCreateInvoice={onCreateInvoiceResponse}
        />
      )}
      <Modal
        closable={false}
        title={null}
        footer={null}
        open={
          ["approved", "expired"].includes(paymentStatus) && !showAutoPayModal
        }
      >
        <Result
          status={paymentStatus === "approved" ? "success" : "error"}
          title={
            paymentStatus === "approved"
              ? "Funds Added Successfully"
              : "Session Expired"
          }
          subTitle={
            paymentStatus === "approved"
              ? "It may take a few moments for your balance to reflect your most recent payment."
              : "Please retry payment."
          }
          extra={[
            <Button type="primary" key="ok" onClick={resetBillingContext}>
              Close
            </Button>,
          ]}
        />
      </Modal>
      <Modal
        closable={false}
        title={null}
        footer={null}
        open={paymentStatus === "pending"}
      >
        <PendingPayment
          onClick={resetBillingContext}
          paymentRef={paymentRef}
          currency={designatedCurrency}
          amount={amountPaid}
        />
      </Modal>
      <Modal
        footer={null}
        title="Fund Wallet"
        onCancel={() => setInvoiceData(undefined)}
        open={showDlocalFlow && invoiceData}
      >
        {invoiceData && (
          <DLocalPaymentForm
            onSubmit={resetBillingContext}
            amount={invoiceData.amount}
            currency={invoiceData.currency}
            apiKey={invoiceData.dlocalSfKey!}
            invoiceId={invoiceData.invoice.invoiceId}
            paymentMethods={invoiceData.paymentMethods || []}
          />
        )}
      </Modal>
      <Modal
        footer={null}
        title="Save Card"
        onCancel={() => setDlocalDetails(undefined)}
        open={dlocalModalOpen && Boolean(dlocalDetails?.dlocalKey)}
      >
        {dlocalDetails && (
          <DlocalSaveCardForm
            onSubmit={() => setDlocalDetails(undefined)}
            currency={dlocalDetails.currency}
            apiKey={dlocalDetails.dlocalKey}
          />
        )}
      </Modal>
    </>
  );
};

export default BillingDashboard;
