import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import { CALLBACKS_QUERY_KEY } from "queries";

interface AllowListingResponse {}

interface AllowListingInput {
  allowListing: boolean;
}

const allowListingCallbacks = async ({ allowListing }: AllowListingInput) => {
  const { data } = await axios.post<AllowListingResponse>(
    "/callback_url/toggle_allow_listing",
    { allow_listing: allowListing },
  );
  return data;
};

export const useCallbackAllowListing = (
  options?: UseMutationOptions<
    AllowListingResponse,
    AxiosError<{ error: string }>,
    AllowListingInput
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: allowListingCallbacks,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [CALLBACKS_QUERY_KEY] });
      options?.onSuccess?.(...args);
    },
  });
};
