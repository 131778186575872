import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { CALLBACKS_QUERY_KEY } from "queries";
import { camelize } from "util/transformers";

interface UpdateCallbackURL {
  id: string;
  urlStatus?: string;
  isDefault?: boolean;
}

interface Response {
  message: string;
}

const updateCallback = async (input: UpdateCallbackURL): Promise<Response> => {
  const { data } = await axios.put<Response>(
    `/callback_url/${input.id}`,
    input,
  );
  return camelize<Response>(data);
};

export const useUpdateCallbackURL = (
  options?: UseMutationOptions<
    Response,
    AxiosError<{ error: string }>,
    UpdateCallbackURL
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateCallback,
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: [CALLBACKS_QUERY_KEY] });
      options?.onSuccess?.(...args);
    },
  });
};
